import type { CustomFetch } from '~/shared/types/custom-fetch.type';
import type { AppLocale } from '~/data/domain/app.locale';
import type { BackendGlobalStatisticsResponse } from '~/features/global-stats/services/global-statistics.type';
import type { GlobalStatisticsModelFormatted } from '~/features/global-stats/models/global-stats.model';
import { createGlobalStatisticsModel } from '~/features/global-stats/models/global-stats.model';
import type { QueryFunctionContext } from '@tanstack/vue-query';
import type { globalStatsKeys } from '~/features/global-stats/queries/global-stats.query';

export const GlobalStatisticsService = (fetch: CustomFetch) => ({
  async getInfo(
    {
      queryKey: [{ currency }],
    }: QueryFunctionContext<ReturnType<(typeof globalStatsKeys)['header']>>,
    locale: AppLocale,
  ): Promise<GlobalStatisticsModelFormatted> {
    const data: BackendGlobalStatisticsResponse = await fetch('api/board/header-summary-info', {
      credentials: 'include',
    });

    return createGlobalStatisticsModel(data.header_summary_data, locale, currency);
  },
});
