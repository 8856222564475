import type { AppLocale } from '~/data/domain/app.locale';
import { formatNumber, formatPrice } from '~/utils/number-formatter';
import type { AppCurrency } from '~/data/domain/currency';
import type { BackendGlobalStatisticsModel } from '~/features/global-stats/services/global-statistics.type';
import type { NumberFormatOptions } from '@intlify/core-base';

export interface GlobalStatisticsModel {
  dapps: number;
  blockchains: number;
  activeUsers: number;
  volume30d: number;
  transactions30d: number;
}

export interface GlobalStatisticsModelFormatted extends GlobalStatisticsModel {
  dappsFormatted: string;
  blockchainsFormatted: string;
  activeUsersFormatted: string;
  volume30dFormatted: string;
  transactions30dFormatted: string;
}

export function formatGlobalStatisticsModel(
  model: GlobalStatisticsModel,
  locale: AppLocale,
  currency: AppCurrency,
) {
  const format = (number: number, isPrice?: boolean): string => {
    const options: NumberFormatOptions = {
      notation: 'compact',
      maximumFractionDigits: 2,
    };

    if (isPrice) return formatPrice(number, locale, currency, options);

    return formatNumber(number, locale, options);
  };

  return {
    dappsFormatted: format(model.dapps ?? 0),
    blockchainsFormatted: format(model.blockchains ?? 0),
    activeUsersFormatted: format(model.activeUsers ?? 0),
    volume30dFormatted: format(model.volume30d ?? 0, true),
    transactions30dFormatted: format(model.transactions30d ?? 0, true),
  };
}

export function createGlobalStatisticsModel(
  raw: BackendGlobalStatisticsModel,
  locale: AppLocale,
  currency: AppCurrency,
): GlobalStatisticsModelFormatted {
  const baseModel: GlobalStatisticsModel = {
    dapps: raw.dapps ?? 0,
    blockchains: raw.blockchains ?? 0,
    activeUsers: raw.active_users ?? 0,
    volume30d: raw['30d_volume'] ?? 0,
    transactions30d: raw['30d_transactions'] ?? 0,
  };

  return {
    ...baseModel,
    ...formatGlobalStatisticsModel(baseModel, locale, currency),
  };
}
