<template>
  <ul class="tw-flex tw-items-center tw-gap-8">
    <li
      v-for="item in headerStats"
      :key="item.label"
      class="tw-flex tw-gap-2.5 tw-items-center tw-justify-center tw-text-225 tw-font-semibold tw-tracking-widest tw-leading-300"
    >
      <span class="tw-uppercase tw-whitespace-nowrap"> {{ item.label }}: </span>
      <de-skeleton v-if="isLoading" class="!tw-w-800 tw-flex-shrink-0 !tw-bg-primary-500" />
      <span v-else class="tw-text-accent-500">{{ item.value }}</span>
    </li>
  </ul>
</template>

<script setup lang="ts">
import DeSkeleton from '~/shared/components/lib/DeSkeleton.vue';
import { useUserStore } from '~/store/user';
import { useAppStore } from '~/store/app';
import { useI18n } from 'vue-i18n';
import { useNuxtApp } from '#app';
import { computed, onServerPrefetch, watch } from 'vue';
import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { globalStatsKeys } from '~/features/global-stats/queries/global-stats.query';
import { GlobalStatisticsService } from '~/features/global-stats/services/global-statistics.service';
import { TWENTY_FOUR_HOURS_IN_MILLISECONDS } from '~/shared/constants/time-duration.constant';
import type { AppLocale } from '~/data/domain/app.locale';
import {
  formatGlobalStatisticsModel,
  type GlobalStatisticsModelFormatted,
} from '~/features/global-stats/models/global-stats.model';

const userStore = useUserStore();
const appStore = useAppStore();
const { t } = useI18n();

const { $customFetch } = useNuxtApp();
const GlobalStatisticsRepo = GlobalStatisticsService($customFetch);

const {
  data: statistics,
  suspense,
  error,
  isLoading,
} = useQuery({
  queryKey: computed(() => globalStatsKeys.header(userStore.user.currencyShortName)),
  queryFn: (queryKey) => GlobalStatisticsRepo.getInfo(queryKey, appStore.locale),
  staleTime: TWENTY_FOUR_HOURS_IN_MILLISECONDS,
});

onServerPrefetch(async () => {
  await suspense();
});

if (error.value) {
  console.error(error.value);
}

const headerStats = computed(() => ({
  dapps: {
    value: statistics.value?.dappsFormatted ?? null,
    label: t('common.globalStats.dapps'),
  },
  blockchains: {
    value: statistics.value?.blockchainsFormatted ?? null,
    label: t('common.globalStats.blockchains'),
  },
  activeUsers: {
    value: statistics.value?.activeUsersFormatted ?? null,
    label: t('common.globalStats.activeUsers'),
  },
  volume30d: {
    value: statistics.value?.volume30dFormatted ?? null,
    label: t('common.globalStats.volume30d'),
  },
  transactions30d: {
    value: statistics.value?.transactions30dFormatted ?? null,
    label: t('common.globalStats.transactions30d'),
  },
}));

const queryClient = useQueryClient();

function updateCache(locale: AppLocale) {
  queryClient.setQueryData(
    globalStatsKeys.header(userStore.user.currencyShortName),
    (oldData: GlobalStatisticsModelFormatted) => {
      if (oldData) {
        return {
          ...oldData,
          ...formatGlobalStatisticsModel(oldData, locale, userStore.user.currencyShortName),
        };
      }

      return oldData;
    },
  );
}

watch(
  () => appStore.locale,
  (val: AppLocale) => {
    updateCache(val);
  },
);
</script>
