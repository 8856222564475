<template>
  <prime-overlay-panel
    ref="op"
    :pt="{
      root: { class: ['de-overlay-panel', $attrs.class] },
      transition: {
        enterFromClass: 'de-connected-overlay-enter-from',
        enterActiveClass: 'de-connected-overlay-enter-active',
        leaveActiveClass: 'de-connected-overlay-leave-active',
        leaveToClass: 'de-connected-overlay-leave-to',
      },
    }"
  >
    <!--    pass through all slots-->
    <template v-for="(_, slot) in $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </prime-overlay-panel>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const op = ref(null);
function toggle(event: Event) {
  op.value.toggle(event);
}

function hide() {
  op.value.hide();
}

defineExpose({
  toggle,
  hide,
});
</script>

<style lang="postcss">
.de-overlay-panel {
  @apply tw-absolute tw-left-0 tw-top-0 tw-mt-2;
  @apply tw-z-40 tw-transform tw-origin-center;
  @apply tw-shadow-250 tw-bg-primary-800;
  @apply tw-px-3.5 tw-pt-3.5 tw-pb-5;
}
</style>
