<template>
  <de-button
    class="tw-text-primary-400 tw-ring-0 hover:tw-bg-inherit"
    :label="userStore.user.currencyShortName"
    icon="chevron-down-filled"
    :icon-pos="ButtonIconPositionOptions.right"
    icon-class="tw-w-300 tw-h-300 tw-text-primary-50 tw-ml-1"
    @click="onCurrencySwitcherClick"
  />

  <select-currency-dialog v-if="shouldDialogShow" v-model="isVisible" />
</template>

<script setup lang="ts">
import { defineAsyncComponent, ref } from 'vue';
import { ButtonIconPositionOptions } from '~/shared/components/lib/button/button.entity';
import DeButton from '~/shared/components/lib/button/DeButton.vue';
import { useUserStore } from '~/store/user';
const SelectCurrencyDialog = defineAsyncComponent(
  () => import('~/shared/components/select-currency/SelectCurrencyDialog.vue'),
);

const userStore = useUserStore();

const isVisible = ref(false);
const shouldDialogShow = ref(false);

const onCurrencySwitcherClick = () => {
  shouldDialogShow.value = true;
  isVisible.value = true;
};
</script>
