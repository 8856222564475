<template>
  <div
    class="tw-flex tw-justify-between tw-px-10 tw-py-2.5 tw-bg-primary-700 tw-border-b-2 tw-border-primary-800"
  >
    <global-statistics />

    <div class="tw-flex tw-gap-2.5">
      <div class="tw-flex tw-items-center tw-gap-1">
        <select-currency />

        <language-switcher panel-class="tw-w-2400" />
      </div>

      <template v-if="authStore.isLoggedIn">
        <button class="tw-flex" @click="toggleUserMenu">
          <de-avatar :src="userStore.user.profile.photo" />
        </button>

        <de-overlay-panel ref="userMenu" class="tw-w-80">
          <app-user-menu
            menu-item-classes="tw-p-2"
            header-classes="tw-border-b tw-border-primary-600"
            @navigate="toggleUserMenu"
          />
        </de-overlay-panel>
      </template>

      <template v-else>
        <de-button
          :variant="ButtonVariantOptions.confirm"
          :category="ButtonCategoryOptions.secondary"
          :label="$t('common.buttons.logIn')"
          @click="onAuthButtonClick(AuthTab.login)"
        />

        <de-button
          :variant="ButtonVariantOptions.confirm"
          :label="$t('common.buttons.signUp')"
          @click="onAuthButtonClick(AuthTab.signUp)"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import DeButton from '~/shared/components/lib/button/DeButton.vue';
import LanguageSwitcher from '~/shared/components/LanguageSwitcher.vue';
import {
  ButtonCategoryOptions,
  ButtonVariantOptions,
} from '~/shared/components/lib/button/button.entity';
import { useAppStore } from '~/store/app';
import { AuthTab } from '~/data/domain/auth.entity';
import { useAuthStore } from '~/store/auth';
import DeAvatar from '~/shared/components/lib/avatar/DeAvatar.vue';
import AppUserMenu from '~/shared/components/menu/AppUserMenu.vue';
import DeOverlayPanel from '~/shared/components/lib/overlay-panel/DeOverlayPanel.vue';
import SelectCurrency from '~/shared/components/select-currency/SelectCurrency.vue';
import GlobalStatistics from '~/features/global-stats/components/GlobalStatistics.vue';
import { useUserStore } from '~/store/user';

// TOGGLE AUTH DIALOG & MENU METHODS
const store = useAppStore();
const authStore = useAuthStore();
const userStore = useUserStore();

function onAuthButtonClick(activeTab: AuthTab) {
  store.toggleAuthDialog(activeTab);
}

const userMenu = ref();
function toggleUserMenu(event: Event) {
  if (!userMenu.value) return;

  userMenu.value.toggle(event);
}
</script>
